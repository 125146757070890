import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Gelekについて`}</h2>
    <blockquote>
      <p parentName="blockquote">{`すべてのギークの夢を現実に変えます。`}</p>
    </blockquote>
    <p>{`電子DIYプロジェクトを始めることの難しさを知っていますか?`}</p>
    <p>{`マイクロコントローラのハンティング、ソリューションの作成、プロトタイピング、材料の準備
超面倒くさい!私たち(ギークでもある)は、徹底的にそれを解決することを決めました。gelekの設立理由はこれです。`}</p>
    <p>{`元気なクリエーターの発明プロセス全体をサポートするツールやハードウエアをご用意しています:
マイクロボード、スターターキット、自己トレーニング教材、各種コンポーネント、コードライブラリ。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      